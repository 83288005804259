<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <!-- OPTIONS & MANAGEMENT WIDGETS -->
    <v-row v-if="userLevelAboveAdmin">
       <!-- SEARCH FORM -->
       <v-col cols="12" md="6">
        <v-card class="my-0">
          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-radio-group row v-model="form.search.searchBy">
                      <template v-slot:label>
                        <div>{{ $t('views.commissionFee.searchBy') }}<br></div>
                      </template>
                      <v-radio :label="$t('views.commissionFee.name')" value="name"></v-radio>
                      <!-- <v-radio :label="$t('views.commissionFee.rate')" value="rate"></v-radio> -->
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex justify-space-between">
                    <v-form @submit.native.prevent @submit="search">
                      <v-text-field dense outlined v-model="form.search.keyword"
                        placeholder="(keyword)" clearable>
                      </v-text-field>
                    </v-form>
                  </v-col>
                </v-row>
                
              </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- BUTTONS FOR CREATION -->
      <v-col cols="12"  md="6" class="d-flex justify-end">
        <div>
          <v-btn x-large block rounded @click="resetCreateModel();dialog.create.show = true;" color="primary">
            <v-icon left>mdi-plus-box-outline</v-icon>
            {{ $t('views.commissionFee.createButton') }}
          </v-btn>         
        </div>
      </v-col>

    </v-row>
      
    <!-- LIST OF MERCHANTS -->
    <v-row>
      <v-col cols="12">

        <v-card>
          <v-card-text>

            <SSRTable ref="commission-fee-list" :data-url="loadUrl" :data-key="'formulas'" :schema="schema">
              <div slot="name" slot-scope="{item}">
                <span class="text-body-1">{{ item.name }}</span><br>
                <span style="color:#999999">{{ item.merchant_id }}</span>
              </div>

              <div slot="contact" slot-scope="{item}" class="py-1">
                <span class="text-body-1">{{ item.phone }}</span><br>
                <span>{{ item.email }}</span><br>
                <span>{{ item.address }}</span>
              </div>

              <div slot="invoice" slot-scope="{item}" class="py-1">
                <span v-if="item.invoice && item.invoice.type === 'b2b'">
                  {{ $t('views.merchants.invoice.B2B') }}
                </span>
                <span v-else-if="item.invoice && item.invoice.type === 'b2c'">
                  {{ $t('views.merchants.invoice.B2C') }}
                </span>
                <br/>
                <span>{{ item.invoice ? item.invoice.title : '' }}</span><br/>
                <span>{{ item.invoice ? item.invoice.taxId : '' }}</span><br/>
                <span>{{ item.invoice ? item.invoice.email : '' }}</span>
              </div>
              <div slot="beneficiary" slot-scope="{item}" class="py-1">
                <span>{{ item.beneficiary ? item.beneficiary.bankName  : '' }}</span><br/>
                <span>{{ item.beneficiary ? item.beneficiary.bankId : '' }}</span><br/>
                <span>{{ item.beneficiary ? item.beneficiary.accountName : '' }}</span><br/>
                <span>{{ item.beneficiary ? item.beneficiary.accountNo : '' }}</span>
              </div>
              
              <div slot="actions" slot-scope="{item}">
                <v-btn fab x-small elevation="0" color="warning" @click="showUpdateMerchantDialog(item.merchant_id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn rounded depressed small color="indigo" class="ml-1"
                  @click="showDialogOfPaymentConfigs(item)">
                  <v-icon left>mdi-credit-card-outline</v-icon>
                  {{ $t('views.merchants.paymentServicesButton') }}
                </v-btn>
                <v-btn rounded depressed small color="teal darken-3" class="ml-1"
                  @click="showDialogOfCounterSettings(item)">
                  <v-icon left>mdi-cash-register</v-icon>
                  條碼管理
                </v-btn>
                <v-btn rounded depressed small color="blue" class="ml-1"
                  @click="showDialogOfMerchantSettings(item)">
                  <v-icon left>mdi-cog</v-icon>
                  {{ $t('views.merchants.merchantSettingsButton') }}
                </v-btn>
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

   
    <!-- Dialog - Create a new rate -->
    <v-dialog v-model="dialog.create.show" max-width="50%">
      <v-card>
        <v-card-title>
          <strong>{{ $t('views.commissionFee.dialog.titleCreate') }}</strong>
        </v-card-title>

        <v-card-text>
          <v-form ref="mch-create-form" :disabled="dialog.create.isLoading">

            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined dense :label="$t('views.commissionFee.dialog.name')" prepend-icon="mdi-pound"
                    v-model="dialog.create.model.name"
                    :error-messages="validation.firstError('dialog.create.model.name')">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined dense :label="$t('views.commissionFee.dialog.rate')" 
                    prepend-icon="mdi-percent-outline"
                    v-model="dialog.create.model.rate"
                    :error-messages="validation.firstError('dialog.create.model.rate')">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialog.create.show = false; this.form.bank.keyword= ''; validation.reset();"
            :disabled="dialog.create.isLoading">
            {{ $t('views.merchants.dialog.buttonCancel') }}
          </v-btn>
          <v-btn color="amber" @click="createFormula"
            :disabled="dialog.create.isLoading">
            {{ $t('views.merchants.dialog.buttonCreate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';
import { Decimal } from 'decimal.js'

const Validator = SimpleVueValidation.Validator;

export default {
  components: {
    SSRTable
  },

  mounted() {
    // this.fetchCommissionFee();
  },

  methods: {
    search() {
      console.log('[DEBUG] search', this.form.search);
      if (this.form.search.keyword)
        this.form.search.searchQueryString = `search=${this.form.search.searchBy}:${encodeURIComponent(this.form.search.keyword)}`;
      else 
        this.form.search.searchQueryString = '';
    },
    // async fetchCommissionFee(merchantId) {
    //   try {
    //     let response = await axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/formulas`);
    //     this.formulas = response.data.data;
    //   } catch (err) {
    //     console.error(`Failed to fetch commission fee formulas`, err);
    //     this.$notify({
    //       title: 'Cannot fetch commission fee formulas!',
    //       type: 'error'
    //     });
    //     throw err;
    //   }
    // },


    createFormula() {
      
      let validators = [
        'dialog.create.model.name',
        'dialog.create.model.rate'
        
      ];

      this.$validate(validators)
      .then(valid => {
        if (!valid) return;

        this.dialog.create.isLoading = true;
        let data = Object.assign({}, this.dialog.create.model);
        data.rate = new Decimal(data.rate).div(new Decimal(100));
        data.rate = data.rate.toNumber();

        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/formulas`,
          data
        )
        .then(response => {
          this.$refs['commission-fee-list'].loadData();
          this.$notify({
            text: `Commission fee formula created: ${this.dialog.create.model.name}.`,
            type: 'success'
          });
        })
        .catch(err => {
          console.error(`Failed to create a new commission fee formula`, err);
          this.$notify({
            text: `Merchant creation failed (${this.dialog.create.model.name}): ${err.message}`,
            type: 'error'
          });
        })
        .finally(() => {          
          this.dialog.create.show = false;
          this.dialog.create.isLoading = false;
          // Reset some form fields.
          this.resetCreateModel();
        });
      });
    },
    resetCreateModel () {
      // Reset some form fields.
      this.dialog.create.model.name = '';
      this.dialog.create.model.rate = '';
      this.validation.reset();
    },
  
  },

  data() {
    return {
      scope: {
      },

      form: {
        search: {
          searchBy: 'name',
          keyword: '',
          searchQueryString: ''
        }
      },
      dialog: {
        create: {
          show: false,
          isLoading: false,
          model: {
            name: '', 
            rate: ''
          }
        },
        
      },

      // formulas: []
    };
  },
  watch: {},

  computed: {
    loadUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/formulas` 
        + (this.form.search.searchQueryString? `?${this.form.search.searchQueryString}`: '')
    },

   
    schema() {
      let ret = [
        { value: 'id', text: this.$t('views.commissionFee.id'), sortable: false },
        { value: 'type', text: this.$t('views.commissionFee.type'), sortable: false },
        { value: 'name', text: this.$t('views.commissionFee.name'), sortable: false },
        { value: 'formula', text: this.$t('views.commissionFee.formula'), sortable: false },
      ];
      return ret;
    },

    user() {
      return this.$store.getters.user;
    },
    userLevelAboveAdmin() {
      return [ 'superadmin' ].indexOf(this.$store.getters.user['level']) >= 0;
    },
  },

  validators: {
    'dialog.create.model.name': v => {
      return Validator.value(v).required().regex(/^[\u4e00-\u9fa5A-Za-z0-9]+$$/, 'The field must contain only alphabets, digits and Chinese character.');
    },
    'dialog.create.model.rate': v => {
      return Validator.value(v).required().between(0, 100).regex(/^\d+(\.\d{1,4})?$/, 'Up to the fourth decimal place can be entered.');
    },
  }
}
</script>
